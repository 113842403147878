import React, { useRef, useLayoutEffect } from "react";
import * as solutionsToNeedsStyles from "./SolutionsToNeeds.module.scss";
import solutionsToNeedImg from "../../../../assets/images/how-we-work-cover.webp";
import { FadeInUPTextAnimationWithTrigger } from "../../../../../GSAPSnippets";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { StaticImage } from "gatsby-plugin-image";

gsap.registerPlugin(ScrollTrigger);

const SolutionsToNeeds = () => {
  const solutionsToNeedsSectionRef = useRef();

  useLayoutEffect(() => {
    FadeInUPTextAnimationWithTrigger(
      "solutionToNeedsText",
      "solutions-to-needs-section"
    );
  });

  const subtitleString = "Solutions to suit your needs";
  const descString = `We help in developing digital products from scratch, deliver
  cutting-edge software solutions to refine the technology, and
  deliver complete engineering solutions.`;
  return (
    <section
      ref={solutionsToNeedsSectionRef}
      className={`es-section__dark solutions-to-needs-section ${solutionsToNeedsStyles.solutionsToNeedsSection}`}
    >
      <div className="solutionToNeedsText">
        <div className={solutionsToNeedsStyles.solutionsToNeedsText}>
          <span
            className={`es-subtitle-text es-subtitle-text__gray ${solutionsToNeedsStyles.solutionsToNeedsSubtitle}`}
          >
            {subtitleString}
          </span>
          <h2>{descString}</h2>
        </div>
        <div className={solutionsToNeedsStyles.solutionsToNeedsImgWrapper}>
          <StaticImage
            src="../../../../assets/images/how-we-work-cover.webp"
            alt="how we work"
            layout="fullWidth"
            placeholder="none"
          />
        </div>
      </div>
    </section>
  );
};

export default SolutionsToNeeds;
