import React, { useLayoutEffect, useRef } from "react";
import * as howWeWorkFeaturesStyles from "./HowWeWorkFeatures.module.scss";
import { HowWeWorkFeaturesData } from "../../../../../data";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const HowWeWorkFeatures = () => {
  const HWWSectionRefs = useRef([]);
  HWWSectionRefs.current = [];

  const addToHWWFeaturesSectionRefs = (el) => {
    if (el && !HWWSectionRefs.current.includes(el)) {
      HWWSectionRefs.current.push(el);
    }
  };
  let HWWSectionAnimation = null;
  useLayoutEffect(() => {
    HWWSectionRefs.current.forEach((el, index) => {
      const q = gsap.utils.selector(el);

      HWWSectionAnimation = gsap.fromTo(
        q(".HWWFeaturesSec-wrapper"),
        {
          opacity: 0,
          y: 100,
        },
        {
          opacity: 1,
          y: 0,
          ease: "expo.out",
          duration: 1.5,
          scrollTrigger: {
            id: `section-${index}`,
            trigger: el,
            start: "top center",
            end: "top top",
          },
        }
      );
    });

    return () => {
      HWWSectionAnimation.kill();
    };
  }, []);

  return HowWeWorkFeaturesData.map((item, index) => (
    <section
      ref={addToHWWFeaturesSectionRefs}
      key={index}
      className={`es-section__light how-we-work-features-section ${howWeWorkFeaturesStyles.howWeWorkFeaturesSection}`}
    >
      <div className="HWWFeaturesSec-wrapper">
        <h2>{item.title}</h2>
        <div className={howWeWorkFeaturesStyles.howWeWorkFeaturesGrid}>
          <div className={howWeWorkFeaturesStyles.howWeWorkFeaturesGridText}>
            <p>{item.desc}</p>
          </div>
          <ul>
            {item.list.map((listItem, listItemIndex) => (
              <li key={listItemIndex}>
                <svg
                  width="23"
                  height="24"
                  viewBox="0 0 23 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M9.50657 4.72958C9.44229 4.34815 9.39386 3.97105 9.36257 3.59391C9.31543 3.02819 9.34458 2.45815 9.32786 1.89244C9.32058 1.65244 9.29186 1.02673 9.30343 0.936733C9.40843 0.152448 10.0414 0.032433 10.2266 0.0110044C10.3161 -0.0018527 11.2359 -0.104664 11.4343 0.915336C11.6777 2.16248 11.7557 3.41818 11.6696 4.64818C11.8706 5.69389 12.2143 6.7353 12.6681 7.67815C14.4741 11.4282 17.9156 11.7924 21.6441 11.7582C22.2176 11.7496 22.6937 12.1996 22.7237 12.7696C22.7537 13.3439 22.3269 13.841 21.756 13.8924C19.6719 14.0939 15.9741 15.7653 14.6957 17.5053C14.4283 17.8696 14.2727 18.7482 14.067 19.6096C13.7799 20.8053 13.4383 21.9882 13.0556 22.4853C12.9981 22.5625 12.3 23.351 12.0917 23.4668C11.5911 23.741 11.1904 23.5824 10.9629 23.4367C10.7353 23.291 10.4777 23.0039 10.3749 22.5282C10.2673 22.031 10.3581 21.011 10.3414 20.7924C10.2806 19.9996 10.122 18.4439 9.79844 17.1067C9.62401 16.3867 9.438 15.7224 9.13029 15.3924C7.99115 14.171 6.16972 14.351 4.63586 14.5525C4.45972 14.5739 4.28358 14.5996 4.10786 14.621C3.48344 14.8053 2.82042 14.9424 2.11971 15.0239C1.06156 15.1482 0.887574 14.1753 0.878146 14.111C0.858432 13.9696 0.785138 13.241 1.58271 12.9496C1.66971 12.9196 2.31172 12.7782 2.559 12.7224C2.90357 12.6453 3.25199 12.5853 3.60171 12.5296C7.21414 11.4068 9.17614 8.25244 9.50657 4.72958ZM10.7464 8.62533C12.1101 11.4453 14.1797 12.8082 16.6329 13.4253H16.6324C15.1007 14.2267 13.7074 15.2296 12.969 16.2368C12.6887 16.6182 12.4316 17.3853 12.1997 18.2467C12.0741 17.4453 11.9044 16.5924 11.6803 15.8639C11.4253 15.0324 11.082 14.3425 10.6989 13.931C9.95914 13.1382 9.03085 12.6968 8.0327 12.4782C9.22756 11.4196 10.1336 10.0996 10.7464 8.62533Z"
                    fill="#0066CC"
                  />
                </svg>

                <span>{listItem}</span>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </section>
  ));
};

export default HowWeWorkFeatures;
