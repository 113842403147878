import React, { useLayoutEffect, useRef } from "react";
import * as howWeWorkStyles from "./HowWeWork.module.scss";
import { FadeInUPTextAnimationWithTrigger } from "../../../../../GSAPSnippets";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const howWeWorkGrid = [
  {
    title: "Handpicked Professionals",
    desc: `Each project we undertake is completed by a carefully curated mosaic of the most suitable minds.`,
  },
  {
    title: "Best-Bias",
    desc: `We have a huge network of the top 1% of software design and development elites who are the best at what they do.`,
  },
  {
    title: "Compassionate Demeanor",
    desc: `Our clients enjoy the intimacy of small businesses and the discipline of large corporations minus the red taping.`,
  },
];

const HowWeWork = () => {
  const howWeWorkSectionRef = useRef();
  useLayoutEffect(() => {
    FadeInUPTextAnimationWithTrigger(
      "HWWSecText-wrapper",
      "how-we-work-section"
    );
  });

  const subtitleString = "How We Work";
  const descString = `endshift made possible the coming together of across-domain
  specialists from across the world under one label.
  We power sophisticated tech projects with impactful solutions to
  facilitate success.`;
  return (
    <section
      ref={howWeWorkSectionRef}
      className={`how-we-work-section ${howWeWorkStyles.howWeWorkSection}`}
    >
      <div className="HWWSecText-wrapper">
        <span
          className={`es-subtitle-text es-subtitle-text__gray ${howWeWorkStyles.howWeWorkSubtitle}`}
        >
          {subtitleString}
        </span>
        <h3>{descString}</h3>
        <div className={howWeWorkStyles.howWeWorkGrid}>
          {howWeWorkGrid.map((item, index) => (
            <div className={howWeWorkStyles.howWeWorkGridItem} key={index}>
              <div className={howWeWorkStyles.howWeWorkGridItemContent}>
                <h4>{item.title}</h4>
                <p>{item.desc}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default HowWeWork;
