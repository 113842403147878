import React, { useRef, useLayoutEffect } from "react";
import * as coverStyles from "./Cover.module.scss";
import howWeWorkCover from "../../../../assets/images/how-we-work-cover.webp";
import { FadeInUPTextAnimation } from "../../../../../GSAPSnippets";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { StaticImage } from "gatsby-plugin-image";

gsap.registerPlugin(ScrollTrigger);

const Cover = () => {
  useLayoutEffect(() => {
    FadeInUPTextAnimation("HWWText-wrapper", 1.2);
  });

  const subtitleString = "Specialists At Work";
  const descString = `Adding value to businesses every day through edgy software design
  and development solutions. If we weren’t the best at it, we wouldn’t
  do it. We work with sincerity, period.`;
  return (
    <section className={`es-section__light ${coverStyles.coverSection}`}>
      <div>
        <div className="HWWText-wrapper">
          <div className={coverStyles.coverText}>
            <span className="es-subtitle-text">{subtitleString}</span>
            <h2>{descString}</h2>
          </div>
          <div className={coverStyles.coverImgWrapper}>
            <StaticImage
              src="../../../../assets/images/how-we-work-cover.webp"
              alt="how we work"
              layout="fullWidth"
              placeholder="none"
            />
          </div>
        </div>

        <svg
          width="357"
          height="627"
          viewBox="0 0 357 627"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M13.9011 1.00146C39.9932 39.2407 10.857 82.7161 20.4241 126.826C30.4261 172.205 51.5898 171.571 65.9404 232.817C75.0727 271.532 11.0019 383.077 8.10281 422.903C4.76881 470.821 35.4996 495.255 23.1783 542.063C15.7855 569.989 8.39277 597.756 1 625.682"
            stroke="url(#paint0_linear_868_338)"
            strokeMiterlimit="10"
          />
          <path
            d="M28.8304 1.00232C46.2251 26.3894 39.1223 54.3154 35.3534 83.0345C33.469 97.3147 32.4543 111.753 35.6433 126.351C45.3554 171.572 66.519 171.731 79.5651 232.183C82.1743 244.242 77.6807 262.806 70.5779 283.909C55.3575 328.654 29.1203 384.506 23.9019 415.287C23.467 417.826 23.1771 420.206 23.0321 422.427C20.7128 461.619 40.5718 485.419 41.1517 518.422C41.2966 525.721 40.4269 533.496 38.2525 541.905C31.0047 569.831 23.467 597.757 16.0742 625.683"
            stroke="url(#paint1_linear_868_338)"
            strokeMiterlimit="10"
          />
          <path
            d="M43.7621 1.00146C61.0119 26.2298 54.054 53.9969 50.43 82.716C48.5456 96.8376 47.5309 111.277 50.575 125.874C59.9971 170.778 81.3057 171.888 92.9022 231.548C95.2215 243.607 90.583 262.171 83.3352 283.115C67.6799 328.018 43.0373 383.553 38.3987 414.81C37.9638 417.349 37.6739 419.729 37.6739 421.951C35.9344 460.983 55.3585 485.101 55.9384 518.104C56.0833 525.403 55.3586 533.178 53.1843 541.587C46.0814 569.672 38.5436 597.597 31.0059 625.523"
            stroke="url(#paint2_linear_868_338)"
            strokeMiterlimit="10"
          />
          <path
            d="M58.6917 1.00195C75.9415 26.2303 69.1286 53.8388 65.5047 82.3992C63.6202 96.5208 62.7505 110.801 65.6496 125.399C74.9268 169.985 96.0904 172.047 106.527 230.914C108.702 243.131 103.628 261.537 96.2353 282.322C80.1452 327.543 57.0971 382.601 53.0384 414.335C52.7484 416.873 52.4585 419.254 52.3136 421.475C51.009 460.19 69.9983 484.625 70.7231 517.946C70.868 525.245 70.1432 533.019 67.9688 541.429C61.0109 569.513 53.3283 597.439 45.9355 625.682"
            stroke="url(#paint3_linear_868_338)"
            strokeMiterlimit="10"
          />
          <path
            d="M73.7679 1.00195C90.8727 26.0717 84.3497 53.6802 80.5808 82.082C78.8414 96.0449 77.8267 110.484 80.7258 124.923C89.7131 169.191 110.877 172.206 120.009 230.279C121.893 242.655 116.675 260.902 109.137 281.529C92.6122 326.908 71.1587 381.649 67.6798 413.859C67.3899 416.398 67.0999 418.778 67.0999 420.999C66.3751 459.556 84.9295 484.308 85.6543 517.628C85.7993 525.086 85.0745 532.861 83.0451 541.27C76.2322 569.513 68.5494 597.439 61.0117 625.682"
            stroke="url(#paint4_linear_868_338)"
            strokeMiterlimit="10"
          />
          <path
            d="M88.6961 1.00183C105.656 25.9129 99.2779 53.5215 95.654 81.7646C93.9145 95.7275 93.0447 110.008 95.7989 124.446C104.496 168.398 125.515 172.365 133.488 229.644C135.227 242.179 129.574 260.267 121.891 280.735C104.931 326.273 85.0722 380.697 82.173 413.383C81.8831 415.922 81.7381 418.302 81.7381 420.523C81.4482 458.921 99.5678 483.991 100.438 517.311C100.582 524.769 99.8577 532.543 97.8283 540.953C91.1603 569.354 83.3327 597.28 75.7949 625.523"
            stroke="url(#paint5_linear_868_338)"
            strokeMiterlimit="10"
          />
          <path
            d="M103.627 1.00342C120.587 25.7558 114.354 53.2058 110.73 81.4489C108.991 95.4118 108.121 109.533 110.875 123.972C119.427 167.606 140.301 172.684 146.969 229.011C148.419 241.546 142.476 259.634 134.793 279.944C117.398 325.64 99.1336 379.747 96.8143 412.908C96.6693 415.447 96.5243 417.827 96.5243 420.048C96.6693 458.288 114.499 483.675 115.369 516.995C115.514 524.453 114.789 532.386 112.904 540.796C106.381 569.198 98.4088 597.123 90.8711 625.684"
            stroke="url(#paint6_linear_868_338)"
            strokeMiterlimit="10"
          />
          <path
            d="M118.56 1.00146C135.375 25.7538 129.431 53.0449 125.808 81.1293C124.068 94.9335 123.198 109.214 125.952 123.494C134.07 166.811 154.944 172.84 160.452 228.374C161.757 241.068 155.524 258.997 147.551 279.148C129.721 325.004 112.906 378.634 111.167 412.589C111.022 415.128 110.877 417.508 110.877 419.729C111.602 457.81 128.997 483.514 129.866 516.994C130.011 524.61 129.431 532.384 127.402 540.794C120.879 569.354 113.051 597.28 105.514 625.84"
            stroke="url(#paint7_linear_868_338)"
            strokeMiterlimit="10"
          />
          <path
            d="M133.491 1.00146C150.306 25.5952 144.363 52.8863 140.884 80.8121C139.144 94.6163 138.419 108.738 141.029 123.018C149.001 166.017 169.585 173.158 173.934 227.74C174.948 240.592 168.425 258.363 160.453 278.355C142.188 324.369 126.968 377.682 125.808 412.113C125.663 414.652 125.663 417.032 125.663 419.253C126.823 457.016 143.783 483.197 144.797 516.676C145.087 524.292 144.363 532.067 142.478 540.476C136.1 569.037 128.128 597.121 120.59 625.682"
            stroke="url(#paint8_linear_868_338)"
            strokeMiterlimit="10"
          />
          <path
            d="M148.567 1.00073C165.237 25.4358 159.583 52.5683 156.104 80.4941C154.365 94.1396 153.64 108.261 156.249 122.541C163.932 165.223 184.371 173.474 187.56 226.946C188.285 239.957 181.472 257.569 173.499 277.403C154.655 323.734 141.174 376.571 140.594 411.478C140.594 414.017 140.449 416.397 140.594 418.618C142.189 456.223 158.859 482.72 159.873 516.199C160.163 523.816 159.438 531.749 157.699 540.158C151.466 568.877 143.493 596.962 135.811 625.522"
            stroke="url(#paint9_linear_868_338)"
            strokeMiterlimit="10"
          />
          <path
            d="M163.497 1.0022C180.022 25.2786 174.514 52.4112 171.18 80.1783C169.585 93.8238 168.86 107.787 171.325 122.067C178.717 164.431 198.866 173.634 201.041 226.312C201.62 239.482 194.373 256.936 186.255 276.611C166.976 323.101 154.944 375.461 155.089 411.003C155.089 413.542 155.089 415.922 155.234 418.143C157.264 455.589 173.499 482.245 174.659 515.883C174.948 523.499 174.224 531.433 172.484 539.842C166.396 568.562 158.278 596.646 150.596 625.524"
            stroke="url(#paint10_linear_868_338)"
            strokeMiterlimit="10"
          />
          <path
            d="M178.426 1.00122C194.951 25.1189 189.588 52.2516 186.254 79.8601C184.659 93.5056 183.934 107.468 186.399 121.59C193.502 163.637 213.361 173.951 214.52 225.677C214.81 239.005 207.417 256.3 199.155 275.816C179.441 322.465 168.859 374.508 169.729 410.526C169.729 413.065 169.729 415.445 169.874 417.666C172.338 454.954 188.283 481.927 189.443 515.724C189.733 523.499 189.153 531.432 187.413 539.683C181.47 568.56 173.353 596.645 165.67 625.523"
            stroke="url(#paint11_linear_868_338)"
            strokeMiterlimit="10"
          />
          <path
            d="M193.357 1.00195C209.737 25.1196 204.663 52.0934 201.329 79.5431C199.735 93.03 199.01 106.993 201.329 121.115C208.287 163.003 227.711 174.428 227.856 225.043C227.856 238.53 220.174 255.666 211.766 275.024C191.617 321.831 182.63 373.557 184.079 410.051C184.224 412.59 184.224 414.97 184.369 417.191C187.269 454.161 202.924 481.611 204.083 515.407C204.373 523.182 203.793 531.115 202.054 539.525C196.256 568.561 187.993 596.646 180.311 625.524"
            stroke="url(#paint12_linear_868_338)"
            strokeMiterlimit="10"
          />
          <path
            d="M208.29 1.00195C224.67 24.961 219.596 51.7761 216.407 79.2259C214.813 92.7127 214.233 106.517 216.407 120.639C223.075 162.21 242.065 174.745 241.34 224.408C241.195 238.054 233.077 255.19 224.67 274.23C203.941 321.196 196.548 372.446 198.723 409.575C198.868 412.113 199.013 414.494 199.158 416.715C202.636 453.526 217.712 481.293 219.017 515.09C219.306 522.864 218.727 530.798 217.132 539.208C211.479 568.244 203.071 596.328 195.389 625.524"
            stroke="url(#paint13_linear_868_338)"
            strokeMiterlimit="10"
          />
          <path
            d="M223.362 1.00256C239.598 24.8029 234.814 51.6181 231.625 78.9092C230.03 92.2374 229.451 106.2 231.625 120.163C238.003 161.417 256.702 175.063 254.963 223.774C254.528 237.578 246.121 254.556 237.568 273.438C216.405 320.562 210.461 371.495 213.361 409.1C213.505 411.638 213.65 414.018 213.94 416.24C217.854 452.892 232.64 480.977 233.944 514.773C234.234 522.548 233.654 530.64 232.06 539.049C226.552 568.245 218.144 596.329 210.316 625.524"
            stroke="url(#paint14_linear_868_338)"
            strokeMiterlimit="10"
          />
          <path
            d="M238.294 1C254.384 24.6417 249.89 51.457 246.701 78.5894C245.107 91.9176 244.672 105.722 246.701 119.685C252.934 160.621 271.054 175.377 268.445 223.137C267.72 237.1 259.167 253.919 250.47 272.642C228.872 320.084 224.378 370.382 228.002 408.621C228.292 411.16 228.437 413.54 228.582 415.761C232.93 452.255 247.281 480.657 248.731 514.612C249.02 522.545 248.441 530.479 246.991 538.888C241.628 568.083 233.075 596.326 225.393 625.522"
            stroke="url(#paint15_linear_868_338)"
            strokeMiterlimit="10"
          />
          <path
            d="M253.223 1.00073C269.314 24.4838 264.82 51.1405 261.776 78.2729C260.326 91.6011 259.746 105.246 261.776 119.209C267.719 159.828 285.404 175.696 281.925 222.503C280.91 236.624 272.068 253.285 263.37 272.008C241.192 319.608 238.438 369.589 242.642 408.463C242.931 411.002 243.076 413.382 243.366 415.603C248.15 451.938 262.211 480.499 263.66 514.613C263.95 522.546 263.515 530.638 261.921 538.889C256.702 568.243 248.15 596.486 240.322 625.839"
            stroke="url(#paint16_linear_868_338)"
            strokeMiterlimit="10"
          />
          <path
            d="M268.153 1.00195C284.098 24.485 279.894 50.9827 276.85 77.9564C275.401 91.126 274.821 104.772 276.85 118.735C282.504 159.037 299.609 176.014 295.55 221.87C294.245 236.15 285.113 252.651 276.271 271.215C253.657 318.975 252.353 368.638 257.281 407.988C257.571 410.527 257.861 412.907 258.151 415.287C263.37 451.305 277.14 480.341 278.59 514.455C278.88 522.389 278.445 530.481 276.995 538.89C271.922 568.244 263.225 596.487 255.397 626"
            stroke="url(#paint17_linear_868_338)"
            strokeMiterlimit="10"
          />
          <path
            d="M283.084 1.00256C299.029 24.3269 294.97 50.8247 291.926 77.6398C290.476 90.8093 290.041 104.455 291.926 118.259C297.434 158.244 313.959 176.332 309.031 221.235C307.436 235.674 298.014 252.017 289.172 270.423C266.124 318.341 266.124 367.528 271.922 407.513C272.212 410.052 272.502 412.431 272.937 414.812C278.59 450.671 291.926 480.025 293.52 514.138C293.955 522.072 293.375 530.164 291.926 538.573C286.997 568.086 278.3 596.329 270.327 625.841"
            stroke="url(#paint18_linear_868_338)"
            strokeMiterlimit="10"
          />
          <path
            d="M298.161 1.00195C313.961 24.1676 310.047 50.6655 307.148 77.3219C305.698 90.3328 305.263 103.978 307.148 117.783C312.366 157.45 328.312 176.807 322.658 220.441C320.774 235.198 311.062 251.223 302.074 269.47C278.446 317.547 280.041 366.417 286.564 406.877C286.999 409.416 287.289 411.796 287.579 414.176C293.667 449.877 306.713 479.548 308.308 513.821C308.742 521.913 308.163 530.005 306.858 538.414C301.929 568.085 293.232 596.328 285.404 626"
            stroke="url(#paint19_linear_868_338)"
            strokeMiterlimit="10"
          />
          <path
            d="M313.089 1.00195C328.744 24.009 325.121 50.3483 322.222 77.0047C320.772 90.0155 320.482 103.502 322.222 117.306C327.15 156.656 342.515 177.125 336.137 219.807C333.963 234.722 323.961 250.589 314.974 268.677C290.911 316.912 293.955 365.465 301.203 406.401C301.638 408.94 301.928 411.32 302.363 413.7C308.741 449.242 321.642 479.23 323.236 513.503C323.671 521.595 323.236 529.687 321.787 538.097C317.003 567.768 308.161 596.011 300.333 625.841"
            stroke="url(#paint20_linear_868_338)"
            strokeMiterlimit="10"
          />
          <path
            d="M328.022 1.00183C343.678 23.8502 340.199 50.1895 337.3 76.6873C335.85 89.5395 335.56 103.185 337.3 116.83C342.083 155.863 356.724 177.442 349.621 219.172C347.012 234.245 336.865 249.954 327.877 267.883C303.235 316.278 307.873 364.354 315.846 405.926C316.281 408.464 316.716 410.844 317.151 413.224C323.963 448.449 336.43 478.913 338.169 513.186C338.604 521.278 338.169 529.528 336.865 537.779C332.226 567.609 323.384 595.852 315.411 625.682"
            stroke="url(#paint21_linear_868_338)"
            strokeMiterlimit="10"
          />
          <path
            d="M342.952 1.0022C358.462 23.8505 355.128 50.0309 352.374 76.37C351.069 89.2222 350.779 102.709 352.229 116.355C356.723 155.07 370.783 177.76 362.956 218.538C360.057 233.77 349.765 249.32 340.487 267.091C315.41 315.643 321.353 363.403 330.196 405.45C330.775 407.989 331.065 410.368 331.645 412.748C338.893 447.814 351.069 478.438 352.809 513.028C353.244 521.278 352.809 529.37 351.649 537.78C347.156 567.609 338.168 596.011 330.196 626"
            stroke="url(#paint22_linear_868_338)"
            strokeMiterlimit="10"
          />
          <path
            d="M358.025 1.00183C373.535 23.6915 370.346 49.7133 367.592 76.0524C366.287 88.9046 365.997 102.233 367.447 115.878C371.651 154.276 384.987 178.077 376.579 217.903C373.39 233.294 362.808 248.685 353.531 266.297C327.874 315.008 335.412 362.45 344.979 404.973C345.558 407.353 345.993 409.892 346.573 412.272C354.256 447.179 365.997 478.12 367.882 512.709C368.317 520.96 367.882 529.211 366.722 537.462C362.373 567.45 353.386 595.852 345.414 625.841"
            stroke="url(#paint23_linear_868_338)"
            strokeMiterlimit="10"
          />
          <path
            d="M372.958 1.00195C388.324 23.533 385.28 49.5548 382.67 75.7352C381.366 88.4288 381.076 101.757 382.525 115.402C386.584 153.483 399.051 178.394 390.063 217.268C386.439 232.818 375.713 248.05 366.29 265.504C340.053 314.374 349.04 361.498 359.477 404.497C360.057 406.877 360.492 409.416 361.072 411.796C369.19 446.545 380.641 477.803 382.525 512.392C382.96 520.643 382.67 528.894 381.511 537.303C377.307 567.45 368.175 595.694 360.202 625.841"
            stroke="url(#paint24_linear_868_338)"
            strokeMiterlimit="10"
          />
          <path
            d="M387.889 1.00122C403.109 23.3736 400.355 49.3955 397.746 75.4172C396.441 88.1108 396.296 101.439 397.601 114.926C401.37 152.848 413.256 178.711 403.544 216.633C399.63 232.341 388.614 247.415 379.192 264.709C352.52 313.738 362.956 360.387 373.973 404.179C374.553 406.559 375.133 409.098 375.713 411.478C384.265 446.068 395.427 477.643 397.311 512.233C397.746 520.484 397.456 528.735 396.296 537.144C392.238 567.291 383.105 595.693 374.988 625.999"
            stroke="url(#paint25_linear_868_338)"
            strokeMiterlimit="10"
          />
          <defs>
            <linearGradient
              id="paint0_linear_868_338"
              x1="0.345522"
              y1="313.256"
              x2="67.5945"
              y2="313.256"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="white" />
              <stop offset="1" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_868_338"
              x1="15.3052"
              y1="313.257"
              x2="80.9506"
              y2="313.257"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="white" />
              <stop offset="1" />
            </linearGradient>
            <linearGradient
              id="paint2_linear_868_338"
              x1="30.2675"
              y1="313.256"
              x2="94.3286"
              y2="313.256"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="white" />
              <stop offset="1" />
            </linearGradient>
            <linearGradient
              id="paint3_linear_868_338"
              x1="45.2276"
              y1="313.257"
              x2="107.715"
              y2="313.257"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="white" />
              <stop offset="1" />
            </linearGradient>
            <linearGradient
              id="paint4_linear_868_338"
              x1="60.1891"
              y1="313.257"
              x2="121.115"
              y2="313.257"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="white" />
              <stop offset="1" />
            </linearGradient>
            <linearGradient
              id="paint5_linear_868_338"
              x1="75.1478"
              y1="313.257"
              x2="134.525"
              y2="313.257"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="white" />
              <stop offset="1" />
            </linearGradient>
            <linearGradient
              id="paint6_linear_868_338"
              x1="90.1095"
              y1="313.258"
              x2="147.949"
              y2="313.258"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="white" />
              <stop offset="1" />
            </linearGradient>
            <linearGradient
              id="paint7_linear_868_338"
              x1="105.072"
              y1="313.256"
              x2="161.387"
              y2="313.256"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="white" />
              <stop offset="1" />
            </linearGradient>
            <linearGradient
              id="paint8_linear_868_338"
              x1="120.034"
              y1="313.256"
              x2="174.835"
              y2="313.256"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="white" />
              <stop offset="1" />
            </linearGradient>
            <linearGradient
              id="paint9_linear_868_338"
              x1="134.995"
              y1="313.256"
              x2="188.294"
              y2="313.256"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="white" />
              <stop offset="1" />
            </linearGradient>
            <linearGradient
              id="paint10_linear_868_338"
              x1="149.956"
              y1="313.257"
              x2="201.765"
              y2="313.257"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="white" />
              <stop offset="1" />
            </linearGradient>
            <linearGradient
              id="paint11_linear_868_338"
              x1="164.916"
              y1="313.256"
              x2="215.245"
              y2="313.256"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="white" />
              <stop offset="1" />
            </linearGradient>
            <linearGradient
              id="paint12_linear_868_338"
              x1="179.877"
              y1="313.257"
              x2="228.74"
              y2="313.257"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="white" />
              <stop offset="1" />
            </linearGradient>
            <linearGradient
              id="paint13_linear_868_338"
              x1="194.84"
              y1="313.257"
              x2="242.267"
              y2="313.257"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="white" />
              <stop offset="1" />
            </linearGradient>
            <linearGradient
              id="paint14_linear_868_338"
              x1="209.798"
              y1="313.257"
              x2="255.85"
              y2="313.257"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="white" />
              <stop offset="1" />
            </linearGradient>
            <linearGradient
              id="paint15_linear_868_338"
              x1="224.76"
              y1="313.255"
              x2="269.491"
              y2="313.255"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="white" />
              <stop offset="1" />
            </linearGradient>
            <linearGradient
              id="paint16_linear_868_338"
              x1="239.72"
              y1="313.256"
              x2="283.177"
              y2="313.256"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="white" />
              <stop offset="1" />
            </linearGradient>
            <linearGradient
              id="paint17_linear_868_338"
              x1="254.186"
              y1="313.257"
              x2="296.905"
              y2="313.257"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="white" />
              <stop offset="1" />
            </linearGradient>
            <linearGradient
              id="paint18_linear_868_338"
              x1="267.952"
              y1="313.257"
              x2="310.672"
              y2="313.257"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="white" />
              <stop offset="1" />
            </linearGradient>
            <linearGradient
              id="paint19_linear_868_338"
              x1="281.599"
              y1="313.257"
              x2="324.476"
              y2="313.257"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="white" />
              <stop offset="1" />
            </linearGradient>
            <linearGradient
              id="paint20_linear_868_338"
              x1="295.136"
              y1="313.257"
              x2="338.309"
              y2="313.257"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="white" />
              <stop offset="1" />
            </linearGradient>
            <linearGradient
              id="paint21_linear_868_338"
              x1="308.582"
              y1="313.257"
              x2="352.18"
              y2="313.257"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="white" />
              <stop offset="1" />
            </linearGradient>
            <linearGradient
              id="paint22_linear_868_338"
              x1="321.942"
              y1="313.257"
              x2="366.078"
              y2="313.257"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="white" />
              <stop offset="1" />
            </linearGradient>
            <linearGradient
              id="paint23_linear_868_338"
              x1="335.229"
              y1="313.257"
              x2="380.007"
              y2="313.257"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="white" />
              <stop offset="1" />
            </linearGradient>
            <linearGradient
              id="paint24_linear_868_338"
              x1="348.46"
              y1="313.257"
              x2="393.975"
              y2="313.257"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="white" />
              <stop offset="1" />
            </linearGradient>
            <linearGradient
              id="paint25_linear_868_338"
              x1="361.634"
              y1="313.256"
              x2="407.974"
              y2="313.256"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="white" />
              <stop offset="1" />
            </linearGradient>
          </defs>
        </svg>
      </div>
    </section>
  );
};

export default Cover;
