import React, { useLayoutEffect, useRef } from "react";
import CTAsSection from "../components/CTAsSection/CTAsSection";
import Footer from "../components/Footer/Footer";
import Header from "../components/Header/Header";
import Cover from "../components/sections/HowWeWorkPageSections/Cover/Cover";
import HowWeWork from "../components/sections/HowWeWorkPageSections/HowWeWork/HowWeWork";
import HowWeWorkFeatures from "../components/sections/HowWeWorkPageSections/HowWeWorkFeatures/HowWeWorkFeatures";
import SolutionsToNeeds from "../components/sections/HowWeWorkPageSections/SolutionsToNeeds/SolutionsToNeeds";
import { HowWeWorkCTAsData } from "../../data";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import LoadingScreen from "../components/LoadingScreen/LoadingScreen";
import { Helmet } from "react-helmet";
import { FadeInUPTextAnimationWithTrigger } from "../../GSAPSnippets";

gsap.registerPlugin(ScrollTrigger);

const HowWeWorkPage = () => {
  return (
    <>
      <Helmet>
        <title>How we work</title>
        <meta
          name="description"
          content="endshift is a solution of the last resort. It helps enterprises, small to large businesses, and startups to scale and remove any technological congestion."
        />
        <link rel="canonical" href="https://endshift.io/" />
      </Helmet>
      <Header dataColor="header-dark" />
      <main>
        <Cover />
        <HowWeWork />
        <SolutionsToNeeds />
        <div>
          <HowWeWorkFeatures />
        </div>
        <CTAsSection data={HowWeWorkCTAsData} />
      </main>
      <Footer />
    </>
  );
};

export default HowWeWorkPage;
